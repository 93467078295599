import { render, staticRenderFns } from "./index.vue?vue&type=template&id=226eb520&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=226eb520&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "226eb520",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FrontSummaryCard: require('/builds/dibz15/dibz15.gitlab.io/components/FrontSummaryCard.vue').default})
