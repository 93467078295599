
import Social from '~/assets/social.js'

export default {
  props: {
  },
  data () {
    return {
      interests: [
        { text: 'Web Development & UI Design', icon: 'mdi-television-guide' },
        { text: 'Machine Learning', icon: 'mdi-state-machine' },
        { text: 'Robotics', icon: 'mdi-robot-industrial' },
        // { text: 'CAD Modeling', icon: 'mdi-printer-3d' },
        { text: 'Computer Vision', icon: 'mdi-webcam' }
        // { text: 'OpenGL & GLSL Shaders', icon: 'mdi-checkerboard' }
      ],
      navs: [
        // {
        //   text: 'Resume',
        //   icon: 'mdi-file-account',
        //   color: 'rgba(26, 26, 26, 0.80)',
        //   clickFunction: function readFile () {
        //     window.open(Social.resume, '_blank') // to open in new tab
        //   }
        // },
        { text: 'Projects', icon: 'mdi-wrench', url: '/posts/projects', color: 'rgba(26, 26, 26, 0.8)' },
        { text: 'About Me', icon: 'mdi-account-tie', url: '/about', color: 'rgba(26, 26, 26, 0.8)' }
      ],
      socials: Social.socials,
      resume: Social.resume,
      cv: Social.cv
    }
  }
}
