import Vue from 'vue';

var titleMixin = {
  data() {
    return {
      title: null,
      sharingImage: null,
      description: null
    };
  },
  head() {
    return {
      title: this.title,
      meta: [
        { hid: 'og:title', property: 'og:title', content: this.title},
        { hid: 'description', name: 'description', content: this.description || '' },
        {
          property: 'og:image',
          content: `${this.sharingImage || ''}`
        },
        {
          property: 'og:description',
          content: `${this.description || ''}`
        }
      ],
    };
  },
};

export default {
  titleMixin
}