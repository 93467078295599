

import Social from '~/assets/social.js'
import Head from '~/assets/head.js'
import FrontSummaryCard from '~/components/FrontSummaryCard.vue'

export default {
  components: {
    FrontSummaryCard
  },
  mixins: [Head.titleMixin],
  data () {
    return {
      buttonAnimation: null,
      videoStyle: { height: '500px' },
      title: 'Home',
      description: Social.description
    }
  },
  computed: {
    imageURL () {
      return 'images/home/me_2021_cropped.jpg'
    },
    parallaxHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 200
        case 'sm':
          return 280
        case 'md':
          return 320
        case 'lg':
        case 'xl':
          return 350
        default:
          return 250
      }
    },
    myHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 350
        case 'sm':
          return 450
        case 'md':
        case 'lg':
        case 'xl':
          return 500
        default:
          return 250
      }
    },
    myWidth () {
      const ratio = 0.8
      return this.myHeight * ratio
    },
    videoStyleObject () {
      const heightObj = { height: 0 }
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          heightObj.height = '180px'
          break
        case 'sm':
          heightObj.height = '200px'
          break
        case 'md':
          heightObj.height = '250px'
          break
        case 'lg':
        case 'xl':
          heightObj.height = '300px'
          break
        default:
          heightObj.height = '400px'
      }
      return heightObj
    }
  },
  mounted () {},
  methods: {
    f () {
    }
  }
}
